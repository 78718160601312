import { defineStyleConfig } from "@chakra-ui/react";


export const CalendarWidgetStyle = defineStyleConfig({
    baseStyle: {
        display: "grid",
        gridTemplateAreas: `
            ". date icon ."
            ". time icon ."
        `,
        gridTemplateColumns: "1fr auto auto 1fr",
        justifyItems: "center",
        columnGap: 1,

        borderWidth: 1,
        borderRadius: "md",

        paddingX: 4,
        paddingY: 2,

        backgroundColor: "teal.100",

        ".LorythCalendarWidget--Date": {
            gridArea: "date",
            fontSize: 16,
            lineHeight: 1,
        },

        ".LorythCalendarWidget--Time": {
            gridArea: "time",
            fontSize: 40,
            fontFamily: "'Roboto Mono', monospace",
            lineHeight: 1,
        },


        ".LorythCalendarWidget--Icon": {
            gridArea: "icon",
            fontSize: 64,
        },
    },
    defaultProps: {
        colorScheme: "teal",
    }
})