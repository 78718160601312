import { SyntheticEvent, useCallback, useState } from "react";

import { Box, Icon, chakra } from "@chakra-ui/react";
import { HTMLChakraProps } from "@chakra-ui/system";

import { IconType, CollapseIcon, ExpandIcon, SectionIcon } from "../../icons";

import { useStyles } from "./Navigation";


export interface NavigationGroupProps extends HTMLChakraProps<typeof Box> {
    title?: string
    icon?: IconType
    isCollapsed?: boolean
    onToggle?: (event: SyntheticEvent<HTMLButtonElement>) => void
}

export function NavigationGroup({
    title,
    icon,
    isCollapsed,
    onToggle,
    children,
    ...props
}: NavigationGroupProps) {
    // Only use state if `isCollapsed` is not defined.
    const [collapsed, setCollapsed] = useState(isCollapsed ?? false)
    const showChildren = isCollapsed ?? !collapsed
    const handleToggle = useCallback((event: SyntheticEvent<HTMLButtonElement>) => {
        if (onToggle) {
            onToggle(event)
        }

        if (!event.isDefaultPrevented()) {
            event.preventDefault()
            setCollapsed(value => !value);
        }
    }, [onToggle])

    const styles = useStyles()
    return (
        <Box
            className="loryth-navigation__group"
            role="group"
            __css={styles.group}
            {...props}
        >
            {(title || icon) && (
                <chakra.button
                    className="loryth-navigation__group-title"
                    __css={styles.groupTitle}
                    onClick={handleToggle}
                >
                    <Icon as={icon ?? SectionIcon} fontSize="sm"/>
                    <chakra.span flex="1" textAlign="left">
                        {title}
                    </chakra.span>
                    <Icon as={showChildren ? CollapseIcon : ExpandIcon} fontSize="sm"/>
                </chakra.button>
            )}
            {showChildren && children}
        </Box>
    )
}
