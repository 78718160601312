import { Icon, IconButton, IconButtonProps } from "@chakra-ui/react";
import { FilterIcon } from "../icons";


export function FilterButton(props: IconButtonProps) {
    return (
        <IconButton
            variant="ghost"
            icon={<Icon as={FilterIcon}/>}
            {...props}
        />
    )
}