import { Box, Center, Spinner, Theme, useTheme } from "@chakra-ui/react";
import { HTMLChakraProps } from "@chakra-ui/system"
import { AlertApiError } from "@loryth/components"
import dayjs from "dayjs"
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { TimerApi } from "@loryth/api";
import { formatDate, formatDuration } from "@loryth/commons/format";
import { parseApiTimestamp } from "@loryth/commons/time";
import { getLabelColor } from "../../timer/getLabelColor"
import { useTimerWidgetChartContext } from "./TimerWidgetChartContext"
import { useTimerWidgetChartStyle } from "./TimeWidgetChartStyleContext"



export function TimerWidgetChart(props: HTMLChakraProps<"div">) {
    const { labels, period, periodType } = useTimerWidgetChartContext()
    const { isUninitialized, isLoading, data, isError, error } = TimerApi.useReportQuery({
        step_unit: period.stepUnit,
        min_dt: period?.minDt?.toISOString(),
        max_dt: period?.maxDt?.toISOString(),
    }, {
        // update the chart every minute
        pollingInterval: 60 * 1000,
    })

    const styles = useTimerWidgetChartStyle()
    const theme = useTheme<Theme>()

    if (isError) {
        return (
            <AlertApiError error={error}/>
        )
    }

    if (isUninitialized || isLoading) {
        return (
            <Center>
                <Spinner/>
            </Center>
        )
    }

    const { records, breakdown } = data

    return (
        <Box __css={styles.chart} {...props}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart data={records} barSize={20}>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis
                        dataKey="timestamp"
                        tickFormatter={value => {
                            return formatDate(parseApiTimestamp(value as string))
                        }}
                    />
                    <YAxis tickFormatter={value => {
                        if (!value) {
                            return value
                        }
                        return formatDuration(value as number, { format: "short", maxUnit: "hour", singleUnit: true })
                    }}/>
                    <Tooltip
                        itemStyle={{ color: "black" }}
                        labelFormatter={(label) => {
                            if (periodType === "today") {
                                return dayjs(parseApiTimestamp(label as string)).format("HH:mm")
                            }
                            if (periodType === "last-week") {
                                return dayjs(parseApiTimestamp(label as string)).format("dddd DD, MMM")
                            }
                            if (periodType === "last-month") {
                                return dayjs(parseApiTimestamp(label as string)).format("YYYY-MM-DD")
                            }
                            if (periodType === "last-year" || periodType === "all") {
                                return dayjs(parseApiTimestamp(label as string)).format("MMMM, YYYY")
                            }
                            return formatDate(parseApiTimestamp(label as string))
                        }}
                        formatter={(value) => {
                            return formatDuration(value as number, { format: "short", maxUnit: "hour" })
                        }}
                    />
                    {labels.map(label => (
                        <Bar
                            dataKey={`breakdown.labels.${label}.total_seconds`}
                            stackId="labels"
                            fill={theme.colors[getLabelColor(label) as keyof Theme["colors"]][400]}
                            stroke={theme.colors["blackAlpha"][200]}
                            strokeWidth={2}
                            name={label}
                        />
                    ))}
                    {labels.length === 0 && Object.keys(breakdown.labels).map(label => (
                        <Bar
                            dataKey={`breakdown.labels.${label}.total_seconds`}
                            stackId="labels"
                            fill={theme.colors[getLabelColor(label) as keyof Theme["colors"]][400]}
                            stroke={theme.colors["blackAlpha"][200]}
                            strokeWidth={2}
                            name={label}
                        />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </Box>
    )
}