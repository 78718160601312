import { useCallback, useEffect, useRef } from "react";

import { Button, FormControl, FormLabel, Spinner, Stack } from "@chakra-ui/react";

import { ResetIcon, SaveIcon } from "../icons";
import { MarkdownEditor, MarkdownEditorRef } from "@loryth/components/markdown";
import { useDebounce } from "@loryth/components/useDebounce";


export interface MarkdownFormControlProps {
    value: string
    editValue?: string
    label: string

    isSubmitting?: boolean

    onChange?: (value: string) => void
    onSubmit?: (value: string) => void
}

export function MarkdownFormControl({
    value,
    editValue,
    label,
    onChange,
    onSubmit,
    isSubmitting = false
}: MarkdownFormControlProps) {
    const $input = useRef<MarkdownEditorRef>(null)
    const [debounce, isDebouncePending] = useDebounce()

    useEffect(() => {
        if (!$input.current) {
            // If the value is changed before input is initialized,
            //  there is no need to reset.
            return
        }

        $input.current.reset(value)
    }, [value]);

    const handleOnChange = useCallback((value: string) => {
        if (!onChange) {
            return
        }
        debounce(() => onChange(value))
    }, [onChange, debounce])

    const handleOnReset = useCallback(() => {
        if (!$input.current) {
            // Impossible to happen, but compiler doesn't know.
            console.warn("Unexpected reset: input isn't initialized.")
            return
        }
        if (onChange) {
            onChange(value)
        }
        $input.current.reset(value)
    }, [value, onChange])

    const handleOnSubmit = useCallback(() => {
        if (!$input.current) {
            // Impossible to happen, but compiler doesn't know.
            console.warn("Unexpected submit: input isn't initialized.")
            return
        }
        if ($input.current.value === value) {
            console.warn("Unexpected submit: no changes detected.")
            return
        }

        if (onSubmit) {
            onSubmit($input.current.value)
        }
    }, [value, onSubmit])


    return (
        <FormControl>
            <FormLabel>{label}</FormLabel>
            <Stack gap={1}>
                <MarkdownEditor
                    ref={$input}
                    defaultValue={editValue ?? value}
                    minHeight={400}
                    onChange={handleOnChange}
                    isDisabled={isSubmitting}
                />
                <Stack direction="row" justifyContent="flex-end">
                    {!isSubmitting && editValue && (
                        <>
                            <Button
                                variant="ghost"
                                leftIcon={<SaveIcon/>}
                                size="sm"
                                isDisabled={isDebouncePending}
                                onClick={handleOnSubmit}
                            >
                                submit
                            </Button>
                            <Button
                                variant="ghost"
                                size="sm"
                                leftIcon={<ResetIcon/>}
                                onClick={handleOnReset}
                            >
                                reset
                            </Button>
                        </>
                    )}
                    {isSubmitting && (
                        <Spinner size="sm"/>
                    )}
                </Stack>
            </Stack>
        </FormControl>
    )
}