import { useMemo } from "react"

import { Box, Center, Spinner, Stack } from "@chakra-ui/react"
import { HTMLChakraProps } from "@chakra-ui/system"

import { TimerApi } from "@loryth/api"
import { Timespan } from "@loryth/components"

import { TimerLabelTag } from "../../timer/TimerLabelTag"

import { useTimerWidgetChartContext } from "./TimerWidgetChartContext"
import { useTimerWidgetChartStyle } from "./TimeWidgetChartStyleContext"


export interface TimerWidgetChartLegendProps extends HTMLChakraProps<"div"> {
}

export function TimerWidgetChartLegend({ children, ...props }: TimerWidgetChartLegendProps) {
    const { isLabelIncluded, period, switchLabel } = useTimerWidgetChartContext()
    const { isLoading, isSuccess, data } = TimerApi.useReportQuery({
        step_unit: period.stepUnit,
        min_dt: period?.minDt?.toISOString(),
        max_dt: period?.maxDt?.toISOString(),
    })

    const labelStats = useMemo(() => {
        if (!data) {
            return []
        }
        const { breakdown } = data

        return Object.entries(breakdown.labels).map(([label, stats]) => {
            return {
                label,
                ...stats
            }
        }).sort((a, b) => b.total_seconds - a.total_seconds)
    }, [data]);

    const styles = useTimerWidgetChartStyle()

    return (
        <Box __css={styles.legend} {...props}>
            {isSuccess && (
                <Stack gap={1}>
                    {labelStats.map(stats => (
                        <Stack
                            key={stats.label}
                            direction="row"
                            onClick={() => switchLabel(stats.label)}
                            data-active={
                                isLabelIncluded(stats.label) ? "" : undefined
                            }
                            cursor="pointer"
                            sx={{
                                paddingX: 2,
                                paddingY: 1,
                            }}
                            _active={{
                                backgroundColor: "teal.50",
                                color: "teal.500",
                            }}
                            _hover={{
                                backgroundColor: "teal.100",
                                color: "teal.500",
                            }}
                        >
                            <TimerLabelTag
                                label={stats.label}
                                sx={{
                                    cursor: "pointer",
                                }}
                            />
                            <Timespan
                                value={stats.total_seconds * 1000}
                                sx={
                                    {
                                        marginLeft: "auto",
                                    }
                                }
                            />
                        </Stack>
                    ))}
                </Stack>
            )}
            {isLoading && (
                <Center>
                    <Spinner/>
                </Center>
            )}
            {children}
        </Box>
    )
}
