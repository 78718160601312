import { Icon, Input, InputGroup, InputLeftElement, InputProps, Spinner } from "@chakra-ui/react";

import { ErrorIcon, SearchIcon } from "../icons";
import { forwardRef, Ref } from "react";


export interface SearchBarProps extends InputProps {
    isLoading?: boolean
}

export const SearchInput = forwardRef(({
    isLoading = false,
    isInvalid = false,
    ...props
}: SearchBarProps, ref: Ref<HTMLInputElement>) => {
    return (
        <InputGroup>
            <InputLeftElement p={0}>
                {isLoading && <Spinner size="sm"/>}
                {!isLoading && isInvalid && <Icon as={ErrorIcon}/>}
                {!isLoading && !isInvalid && <Icon as={SearchIcon}/>}
            </InputLeftElement>
            <Input
                ref={ref}
                placeholder="Search..."
                isInvalid={isInvalid}
                {...props}
            />
        </InputGroup>
    )
})