import { useState } from "react";

import { Icon, IconButton, Input, InputGroup, InputProps, InputRightAddon } from "@chakra-ui/react";

import { VisibilityOffIcon, VisibilityOnIcon } from "../icons";


export interface PasswordInputProps extends InputProps {

}

export function PasswordInput(props: PasswordInputProps) {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <InputGroup>
            <Input
                type={showPassword ? "text" : "password"}
                {...props}
            />
            <InputRightAddon
                as={IconButton}
                variant="ghost"
                aria-label={
                    showPassword ? "hide password" : "reveal password"
                }
                icon={
                    <Icon as={showPassword ? VisibilityOffIcon : VisibilityOnIcon}/>
                }
                onClick={() => setShowPassword(!showPassword)}
            />
        </InputGroup>
    )
}