import { CalendarWidgetStyle } from "@loryth/features/core-greeting-widget/CalendarWidgetStyle"
import { FilesystemExplorerStyle } from "@loryth/features/filesystem-explorer/FilesystemExplorerStyle"
import { FilesystemNodePreviewStyle } from "@loryth/features/filesystem/FilesystemNodePreviewStyle"
import { TimerWidgetChartStyle } from "@loryth/features/timer-widget/TimerWidgetChart"

import { CollapsibleTheme } from "./collapsible";
import { CollectionStyle } from "./collection";
import { IconBoxStyle } from "./icon-box";
import { MarkdownEditorStyle } from "./markdown-editor";
import { MarkdownTextStyle } from "./markdown-text";
import { ModalStyle } from "./modal"
import { NavigationStyle } from "./navigation";
import { PageLayoutStyle } from "./page-layout";
import { SectionBodyStyle } from "./section-body";

export const components = {
    Collapsible: CollapsibleTheme,
    Collection: CollectionStyle,
    FilesystemExplorer: FilesystemExplorerStyle,
    FilesystemNodePreview: FilesystemNodePreviewStyle,
    IconBox: IconBoxStyle,
    MarkdownEditor: MarkdownEditorStyle,
    MarkdownText: MarkdownTextStyle,
    Modal: ModalStyle,
    Navigation: NavigationStyle,
    PageLayout: PageLayoutStyle,
    SectionBody: SectionBodyStyle,
    TimerWidgetChart: TimerWidgetChartStyle,
    LorythCalendarWidget: CalendarWidgetStyle,
}