import { useNow } from "@loryth/components"
import clsx from "clsx"
import { chakra, Icon, useStyleConfig } from "@chakra-ui/react"
import { HTMLChakraProps } from "@chakra-ui/system"

import { CalendarIcon } from "@loryth/components/icons"


export interface GreetingWidgetProps extends HTMLChakraProps<"div"> {
}

export function CalendarWidget({ className, ...props }: GreetingWidgetProps) {
    const now = useNow()
    const styles = useStyleConfig("LorythCalendarWidget", {})
    return (
        <chakra.div
            className={clsx("LorythCalendarWidget", className)}
            __css={styles}
            {...props}
        >
            <chakra.span className="LorythCalendarWidget--Date">
                {now.format("dddd, MMMM DD YYYY")}
            </chakra.span>
            <chakra.span className="LorythCalendarWidget--Time">
                {now.format("HH:mm")}
            </chakra.span>
            <Icon as={CalendarIcon} className="LorythCalendarWidget--Icon"/>
        </chakra.div>
    )
}