import { Box, useMultiStyleConfig } from "@chakra-ui/react"
import { HTMLChakraProps } from "@chakra-ui/system"

import { TimerWidgetChartProvider } from "./TimerWidgetChartContext"
import { TimerWidgetChartStyleProvider } from "./TimeWidgetChartStyleContext"
import { useTimerWidgetChart } from "./useTimerWidgetChart"



export interface TimerWidgetChartRootProps extends HTMLChakraProps<"div"> {

}

export function TimerWidgetChartRoot(props: TimerWidgetChartRootProps) {
    const context = useTimerWidgetChart()
    const styles = useMultiStyleConfig("TimerWidgetChart", {})
    return (
        <TimerWidgetChartProvider value={context}>
            <TimerWidgetChartStyleProvider value={styles}>
                <Box __css={styles.root} {...props}/>
            </TimerWidgetChartStyleProvider>
        </TimerWidgetChartProvider>
    )
}