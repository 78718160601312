import { ChangeEvent, KeyboardEvent, useCallback, useEffect, useRef } from "react";

import {
    FormControl,
    FormLabel,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputRightElement, Spinner,
    Stack
} from "@chakra-ui/react";

import { ResetIcon, SaveIcon } from "../icons";
import { useDebounce } from "@loryth/components/useDebounce";


export interface TextFormControlProps {
    value: string
    editValue?: string
    label: string

    isSubmitting?: boolean

    onChange?: (value: string) => void
    onSubmit?: (value: string) => void
}

export function TextFormControl({ value, editValue, label, isSubmitting, onChange, onSubmit }: TextFormControlProps) {
    const $input = useRef<HTMLInputElement>(null)
    const [debounce, isDebouncePending] = useDebounce()

    useEffect(() => {
        if (!$input.current) {
            // If the value is changed before input is initialized,
            //  there is no need to reset.
            return
        }

        $input.current.value = value
    }, [value]);


    const handleOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (!onChange) {
            return
        }
        const value = event.target.value
        debounce(() => onChange(value))
    }, [debounce, onChange])

    const handleOnReset = useCallback(() => {
        if (!$input.current) {
            // Impossible to happen, but compiler doesn't know...
            console.warn("Unexpected reset: input isn't initialized.")
            return
        }
        if (onChange) {
            onChange(value)
        }
        $input.current.value = value
    }, [value, onChange])

    const handleOnSubmit = useCallback(() => {
        if (!$input.current) {
            // Impossible to happen, but compiler doesn't know...
            console.warn("Unexpected submit: input isn't initialized.")
            return
        }
        if ($input.current.value === value) {
            // Abort early, empty state means submit was triggered without any changes.
            console.warn("Unexpected submit: no changes detected.")
            return
        }
        if (onSubmit) {
            onSubmit($input.current.value)
        }
    }, [value, onSubmit])

    const handleOnTitleKeyDown = useCallback(async (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.code === "Enter") {
            event.preventDefault()
            handleOnSubmit()
        }
        if (event.code === "Escape") {
            event.preventDefault()
            handleOnReset()
        }
    }, [handleOnSubmit, handleOnReset])

    return (
        <FormControl>
            <FormLabel>{label}</FormLabel>
            <Stack direction="row" gap={2} alignItems="center">
                <InputGroup>
                    <Input
                        ref={$input}
                        isDisabled={isSubmitting}
                        defaultValue={editValue ?? value}
                        onChange={handleOnChange}
                        onKeyDown={handleOnTitleKeyDown}
                    />
                    {editValue && (
                        <InputRightElement>
                            <IconButton
                                variant="ghost"
                                aria-label="Reset title"
                                icon={<Icon as={ResetIcon}/>}
                                onClick={handleOnReset}
                            />
                        </InputRightElement>
                    )}
                </InputGroup>
                {!isSubmitting && editValue && (
                    <IconButton
                        aria-label="Save"
                        icon={<SaveIcon/>}
                        isDisabled={isDebouncePending}
                        onClick={handleOnSubmit}
                    />
                )}
                {isSubmitting && (
                    <Spinner size="sm"/>
                )}
            </Stack>
        </FormControl>
    )
}